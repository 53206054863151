
import { defineComponent, inject, reactive, watch } from "vue";

import { arrowBackOutline, close } from "ionicons/icons";
import {
  IonPage,
  IonHeader,
  IonCard,
  IonButton,
  IonToolbar,
  IonLabel,
  IonToggle,
  IonItem,
  IonContent,
  IonModal,
  IonIcon
} from "@ionic/vue";
import { useStorage } from "@/composables/useStorage";

export default defineComponent({
  components: {
    IonPage,
    IonHeader,
    IonCard,
    IonButton,
    IonToolbar,
    IonLabel,
    IonToggle,
    IonItem,
    IonContent,
    IonModal,
    IonIcon
  },
  props: { isOpen: Boolean },
  emits: ["closeModal"],
  setup() {
    // Data
    const version = "1.5.4";
    const preferences = reactive({});
    const isMobile = inject("isMobile") as boolean;
    const { getPreferences, setPreferences } = useStorage();

    // Methods
    const init = async () => {
      Object.assign(preferences, await getPreferences());
    };
    const handleDarkModeChange = (e: CustomEvent) => {
      document.body.classList.toggle("dark", e.detail.checked);
    };

    // Watchers
    watch(preferences, async newVal => {
      await setPreferences(newVal);
    });
    return {
      arrowBackOutline,
      close,
      isMobile,
      preferences,
      version,
      handleDarkModeChange,
      init
    };
  }
});
