import { ref } from 'vue';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { FilePicker } from '@capawesome/capacitor-file-picker';

enum Type {
    pdf = 'application/pdf',
    jpg = 'image/jpg',
    png = 'image/png'
}

// This is the file that will be sent up to the server
interface UserFile {
    name?: string;
    type?: Type;
    data?: string;
}

const file = ref<UserFile>();
const setUserFile = (input: UserFile) => {
    file.value = input
}
const clearUserFile = () => {
    file.value = undefined;
}

export const getFile = () => file;

// This is the thumbnail that is displayed to the user.
interface Thumbnail {
    dataUrl?: string;
    type?: Type;
}

const thumbnail = ref<Thumbnail>();

const setThumbnail = (input: Thumbnail) => {
    thumbnail.value = input;
}

const clearThumbnail = () => {
    thumbnail.value = undefined;
}

export const getThumbnail = () => thumbnail;

export const set = async (file: UserFile, isPresignedUrl = false) => {
    const dataUrl = !isPresignedUrl ? `data:image/jpeg;base64,${(file.data as string).replace('\n', '')}` : file.data;
    setThumbnail({ dataUrl, type: file.type });
    setUserFile(file);
}

export const clear = () => {
    clearThumbnail();
    clearUserFile();
}

/**
 * Takes a js File and maps it to a UserFile object.
 */
export function FileToUserFile(file: File): Promise<UserFile> {
    return new Promise((resolve) => {
        const type = file.type as Type;
        if (!type) {
            throw new Error("Unsupported file type");
        }

        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve({
            name: file.name,
            data: (reader.result as string).replace(/^data:(image|application)\/(png|jpeg|jpg|pdf);base64,/, ""),
            type: type
        })
    })
}

export const takePhoto = async () => {

    const cameraPhoto = await Camera.getPhoto({
        resultType: CameraResultType.Base64,
        source: CameraSource.Camera,
        quality: 50
    });

    const userFile = {
        name: "",
        data: cameraPhoto.base64String,
        type: cameraPhoto.format as Type
    }

    return userFile
};

export const pickUserFile = async () => {
    const result = await FilePicker.pickFiles({
        types: ["application/pdf", "image/jpg", "image/jpeg", "image/png"],
        readData: true,
    });
    const file = result.files[0];
    if (!file) {
        throw new Error("Failed to get file");
    }

    const userFile = {
        name: file.name,
        data: file.data,
        type: file.mimeType as Type
    };
    return userFile;
}

// Open up the user image gallery and allow the user to pick an image.
// Only used in iOS. The pickUserFile method works really well
// for gallery/file system on Android.
export const pickUserImage = async () => {
    const result = await FilePicker.pickImages({
        readData: true,
    });
    const file = result.files[0];
    if (!file) {
        throw new Error("Failed to get file");
    }

    const userFile = {
        name: file.name,
        data: file.data,
        type: file.mimeType as Type
    };
    return userFile;
}