
import { defineComponent, reactive } from "vue";
import { arrowBackOutline, closeCircle } from "ionicons/icons";
import { agent } from "@/agent";

import {
  IonHeader,
  IonPage,
  IonChip,
  IonCardTitle,
  IonCard,
  IonButton,
  IonToolbar,
  IonLabel,
  IonInput,
  IonContent,
  IonModal,
  IonIcon,
  IonRow,
  IonCol
} from "@ionic/vue";

export default defineComponent({
  components: {
    IonHeader,
    IonPage,
    IonChip,
    IonCardTitle,
    IonCard,
    IonButton,
    IonToolbar,
    IonLabel,
    IonInput,
    IonContent,
    IonModal,
    IonIcon,
    IonRow,
    IonCol
  },
  props: { isOpen: Boolean },
  setup() {
    const { getAllCategories } = agent();
    const categories = reactive([] as any);

    // Methods
    const init = async () => {
      const res = await getAllCategories();
      if (res && res.data) {
        Object.assign(
          categories,
          res.data.sort((a: any, b: any) => (a.name > b.name ? 1 : -1))
        );
      }
    };
    return {
      arrowBackOutline,
      categories,
      closeCircle,
      init
    };
  }
});
