import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!
  const _component_ion_modal = _resolveComponent("ion-modal")!

  return (_openBlock(), _createBlock(_component_ion_modal, {
    "is-open": _ctx.isOpen,
    onWillPresent: _cache[1] || (_cache[1] = ($event: any) => (_ctx.init())),
    onWillDismiss: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('closeModal')))
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_page, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_header, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_toolbar, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_button, {
                    icon: "",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('closeModal')))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, {
                        icon: _ctx.arrowBackOutline,
                        size: "large"
                      }, null, 8, ["icon"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_content)
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["is-open"]))
}