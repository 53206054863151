import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e9d4a6b6"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "ion-float-right",
  style: {"margin-right":"12px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_toggle = _resolveComponent("ion-toggle")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_card = _resolveComponent("ion-card")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!
  const _component_ion_modal = _resolveComponent("ion-modal")!

  return (_openBlock(), _createBlock(_component_ion_modal, {
    "is-open": _ctx.isOpen,
    onWillPresent: _cache[3] || (_cache[3] = ($event: any) => (_ctx.init())),
    onWillDismiss: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('closeModal')))
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_page, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_header, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_toolbar, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_button, {
                    icon: "",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('closeModal')))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, {
                        icon: _ctx.arrowBackOutline,
                        size: "large"
                      }, null, 8, ["icon"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_content, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_card, { class: "ion-padding" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_label, null, {
                        default: _withCtx(() => [
                          _createTextVNode("Dark Mode")
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_toggle, {
                        modelValue: _ctx.preferences.darkMode,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.preferences.darkMode) = $event)),
                        onIonChange: _ctx.handleDarkModeChange
                      }, null, 8, ["modelValue", "onIonChange"])
                    ]),
                    _: 1
                  }),
                  (_ctx.isMobile)
                    ? (_openBlock(), _createBlock(_component_ion_item, { key: 0 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_label, null, {
                            default: _withCtx(() => [
                              _createTextVNode("Open Camera on Launch")
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_ion_toggle, {
                            modelValue: _ctx.preferences.openCameraOnLaunch,
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.preferences.openCameraOnLaunch) = $event))
                          }, null, 8, ["modelValue"])
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }),
              _createElementVNode("h5", _hoisted_1, " v" + _toDisplayString(_ctx.version), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["is-open"]))
}