
import { defineComponent, ref } from "vue";
import {
  constructOutline,
  logOutOutline,
  menu,
  personOutline
} from "ionicons/icons";
import UserSettingsModal from "@/components/UserSettingsModal.vue";
import AdminModal from "@/components/AdminModal.vue";
import { useStorage } from "@/composables/useStorage";
import { useRouter } from "vue-router";

import {
  IonList,
  IonItem,
  IonPopover,
  IonTitle,
  IonToolbar,
  IonButton,
  IonIcon
} from "@ionic/vue";
import { useNetwork } from "@/composables/useNetwork";

export default defineComponent({
  components: {
    IonList,
    IonItem,
    IonPopover,
    IonTitle,
    IonToolbar,
    IonButton,
    IonIcon,
    UserSettingsModal,
    AdminModal
  },
  props: { title: String },
  async setup() {
    const menuOpen = ref(false);
    const isAdmin = ref(false);
    const userSettingsModal = ref(false);
    const adminModal = ref(false);
    const eventRef = ref();
    const { getUser, setUser } = useStorage();
    const { isInternet } = useNetwork();
    const router = useRouter();
    const user = await getUser();
    isAdmin.value = user.isAdmin;

    // Methods
    const setMenu = (state: boolean, event?: Event) => {
      eventRef.value = event;
      menuOpen.value = state;
    };

    const handleLogout = async () => {
      setMenu(false);
      await setUser(null);
      router.push("/logout");
    };

    const setUserModal = (state: boolean) => {
      if (state === true) {
        menuOpen.value = false;
      }
      userSettingsModal.value = state;
    };

    const setAdminModal = (state: boolean) => {
      if (state === true) {
        menuOpen.value = false;
      }
      adminModal.value = state;
    };
    return {
      adminModal,
      isAdmin,
      constructOutline,
      eventRef,
      isInternet,
      logOutOutline,
      menu,
      menuOpen,
      personOutline,
      userSettingsModal,
      handleLogout,
      setAdminModal,
      setMenu,
      setUserModal
    };
  }
});
