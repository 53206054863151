<template>
  <div class="container">
    <ion-fab
      :class="isIPhone ? 'nudgeDown' : ''"
      vertical="top"
      horizontal="end"
      slot="fixed"
    >
      <ion-fab-button @click="$emit('closeModal')">
        <ion-icon :icon="close"></ion-icon>
      </ion-fab-button>
    </ion-fab>
    <swiper
      class="swiper"
      :zoom="true"
      :navigation="true"
      @imagesReady="imagesReady"
    >
      <swiper-slide v-for="(source, i) in sources" :key="i" class="slide">
        <div class="swiper-zoom-container image-container">
          <img @ionError="loaded" :src="source" /></div
      ></swiper-slide>
    </swiper>
  </div>
</template>

<script lang="js">
import { IonFab, IonFabButton, isPlatform, IonIcon } from "@ionic/vue";
import { close } from "ionicons/icons";
import { Swiper } from "swiper/vue/swiper";
import { SwiperSlide } from "swiper/vue/swiper-slide";
import SwiperCore, { Zoom, Navigation } from "swiper";
import "swiper/swiper.min.css";
import "swiper/modules/zoom/zoom.min.css";
import "swiper/modules/navigation/navigation.min.css";
import "@ionic/vue/css/ionic-swiper.css";
SwiperCore.use([Zoom, Navigation]);

export default {
  components: { Swiper, SwiperSlide, IonFab, IonFabButton, IonIcon },
  props: { sources: Array },
  emits: ["closeModal", "loaded"],
  setup(props, { emit }) {    
    const isIPhone = isPlatform("iphone");
    const loaded = () => {
      emit("loaded");
    };
    const imagesReady = () => {
      emit("loaded");
    }
    return { close, imagesReady, isIPhone, loaded };
  }
};
</script>

<style scoped>
.container {
  height: 100%;
  padding: 0;
}
img {
  height: 100%;
  object-fit: contain;
}
.nudgeDown {
  padding-top: 35px;
}
.swiper {
  height: 100% !important;
  background-color: black;
}
.slider {
  height: 100% !important;
}
.slide {
  display: block !important;
}
</style>
