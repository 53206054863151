import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, renderList as _renderList } from "vue"

const _hoisted_1 = {
  for: "fileInput",
  class: "custom-file-upload",
  style: {"margin-bottom":"5px"}
}
const _hoisted_2 = {
  key: 0,
  class: "errorMessage"
}
const _hoisted_3 = {
  key: 0,
  class: "errorMessage"
}
const _hoisted_4 = {
  key: 0,
  class: "errorMessage"
}
const _hoisted_5 = {
  key: 0,
  class: "errorMessage"
}
const _hoisted_6 = {
  key: 0,
  class: "errorMessage"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_img = _resolveComponent("ion-img")!
  const _component_ion_thumbnail = _resolveComponent("ion-thumbnail")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_popover = _resolveComponent("ion-popover")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_datetime = _resolveComponent("ion-datetime")!
  const _component_ion_select_option = _resolveComponent("ion-select-option")!
  const _component_ion_select = _resolveComponent("ion-select")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_radio = _resolveComponent("ion-radio")!
  const _component_ion_radio_group = _resolveComponent("ion-radio-group")!
  const _component_ZoomableImg = _resolveComponent("ZoomableImg")!
  const _component_ion_page = _resolveComponent("ion-page")!
  const _component_ion_modal = _resolveComponent("ion-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ion_row, { class: "ion-text-justify ion-justify-content-center ion-margin" }, {
      default: _withCtx(() => [
        _createVNode(_component_ion_col, {
          "size-sm": "6",
          "size-md": "9",
          "size-lg": "6"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_row, { class: "ion-text-justify ion-justify-content-center" }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_col, { size: "10" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_thumbnail, { style: {"height":"150px","width":"150px","margin":"7px","display":"inline-block"} }, {
                      default: _withCtx(() => [
                        (!_ctx.file)
                          ? (_openBlock(), _createBlock(_component_ion_img, {
                              key: 0,
                              src: "assets/placeholder.jpg",
                              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.pick()))
                            }))
                          : (_ctx.file.type === 'application/pdf')
                            ? (_openBlock(), _createBlock(_component_ion_img, {
                                key: 1,
                                src: "assets/pdf-placeholder.png"
                              }))
                            : (_openBlock(), _createBlock(_component_ion_img, {
                                key: 2,
                                src: _ctx.thumbnail?.dataUrl,
                                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.imageModal = true))
                              }, null, 8, ["src"])),
                        _createTextVNode(" " + _toDisplayString(_ctx.file?.name || ""), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_col, {
                  size: "2",
                  class: "ion-align-self-center button-column",
                  "size-sm": "2"
                }, {
                  default: _withCtx(() => [
                    (_ctx.isMobile)
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                          _createVNode(_component_ion_button, {
                            id: "chooseFile",
                            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.pickFileClicked($event))),
                            size: "large",
                            style: {"margin-bottom":"5px"}
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_icon, { icon: _ctx.folder }, null, 8, ["icon"])
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_ion_popover, {
                            cssClass: "popover",
                            event: _ctx.popoverEvent,
                            isOpen: _ctx.popoverIsOpen,
                            onDidDismiss: _cache[3] || (_cache[3] = ($event: any) => (_ctx.popoverIsOpen = false))
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_content, { class: "ion-padding" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_list, null, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_ion_item, { onClick: _ctx.setFile }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_ion_icon, {
                                            icon: _ctx.folder,
                                            slot: "start"
                                          }, null, 8, ["icon"]),
                                          _createVNode(_component_ion_label, null, {
                                            default: _withCtx(() => [
                                              _createTextVNode(" File System ")
                                            ]),
                                            _: 1
                                          })
                                        ]),
                                        _: 1
                                      }, 8, ["onClick"]),
                                      _createVNode(_component_ion_item, { onClick: _ctx.setGalleryImage }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_ion_icon, {
                                            icon: _ctx.images,
                                            slot: "start"
                                          }, null, 8, ["icon"]),
                                          _createVNode(_component_ion_label, null, {
                                            default: _withCtx(() => [
                                              _createTextVNode(" Photo Gallery ")
                                            ]),
                                            _: 1
                                          })
                                        ]),
                                        _: 1
                                      }, 8, ["onClick"])
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }, 8, ["event", "isOpen"])
                        ], 64))
                      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                          _createElementVNode("label", _hoisted_1, [
                            _createVNode(_component_ion_icon, { icon: _ctx.folder }, null, 8, ["icon"])
                          ]),
                          _createElementVNode("input", {
                            id: "fileInput",
                            style: {"display":"none"},
                            type: "file",
                            required: "",
                            accept: "image/png, image/jpeg, application/pdf",
                            onChange: _cache[4] || (_cache[4] = ($event: any) => (_ctx.handleBrowserImageUpload($event)))
                          }, null, 32)
                        ], 64)),
                    _createVNode(_component_ion_button, {
                      size: "large",
                      onClick: _ctx.takePhotoClicked,
                      style: {"padding-left":"0","margin-left":"0","margin-top":"5px"}
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_icon, { icon: _ctx.camera }, null, 8, ["icon"])
                      ]),
                      _: 1
                    }, 8, ["onClick"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_ion_item, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_label, null, {
          default: _withCtx(() => [
            _createTextVNode("Receipt Date")
          ]),
          _: 1
        }),
        _createVNode(_component_ion_datetime, {
          modelValue: _ctx.date,
          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.date) = $event)),
          max: _ctx.format(new Date(), 'yyyy-MM-dd'),
          name: "date"
        }, null, 8, ["modelValue", "max"]),
        (_ctx.dateError)
          ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.dateError), 1))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    (_ctx.user.isAdmin)
      ? (_openBlock(), _createBlock(_component_ion_item, { key: 0 }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_label, null, {
              default: _withCtx(() => [
                _createTextVNode("Organization")
              ]),
              _: 1
            }),
            _createVNode(_component_ion_select, {
              modelValue: _ctx.organization,
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.organization) = $event)),
              name: "organization"
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.organizations, (org) => {
                  return (_openBlock(), _createBlock(_component_ion_select_option, {
                    key: org,
                    value: org
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(org), 1)
                    ]),
                    _: 2
                  }, 1032, ["value"]))
                }), 128))
              ]),
              _: 1
            }, 8, ["modelValue"])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_ion_item, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_label, null, {
          default: _withCtx(() => [
            _createTextVNode("Category")
          ]),
          _: 1
        }),
        _createVNode(_component_ion_select, {
          modelValue: _ctx.category,
          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.category) = $event)),
          name: "category"
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredCategories, (cat) => {
              return (_openBlock(), _createBlock(_component_ion_select_option, {
                key: cat,
                value: cat.name
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(cat.name), 1)
                ]),
                _: 2
              }, 1032, ["value"]))
            }), 128))
          ]),
          _: 1
        }, 8, ["modelValue"]),
        (_ctx.categoryError)
          ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.categoryError), 1))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_component_ion_item, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_label, { position: "floating" }, {
          default: _withCtx(() => [
            _createTextVNode("Amount ($)")
          ]),
          _: 1
        }),
        _createVNode(_component_ion_input, {
          name: "amount",
          inputmode: "decimal",
          type: "number",
          enterkeyhint: "next",
          required: "",
          placeholder: "0.00",
          modelValue: _ctx.amount,
          "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.amount) = $event)),
          onIonBlur: _ctx.onAmountBlur
        }, null, 8, ["modelValue", "onIonBlur"]),
        (_ctx.amountError)
          ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.amountError), 1))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_component_ion_item, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_label, { position: "floating" }, {
          default: _withCtx(() => [
            _createTextVNode("Description")
          ]),
          _: 1
        }),
        _createVNode(_component_ion_input, {
          placeholder: "A brief description of your expense",
          modelValue: _ctx.description,
          "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.description) = $event))
        }, null, 8, ["modelValue"]),
        (_ctx.descriptionError)
          ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(_ctx.descriptionError), 1))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    (_ctx.user.isAdmin)
      ? (_openBlock(), _createBlock(_component_ion_item, { key: 1 }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_label, { position: "floating" }, {
              default: _withCtx(() => [
                _createTextVNode("Comment")
              ]),
              _: 1
            }),
            _createVNode(_component_ion_input, {
              modelValue: _ctx.comment,
              "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.comment) = $event))
            }, null, 8, ["modelValue"]),
            (_ctx.commentError)
              ? (_openBlock(), _createElementBlock("p", _hoisted_6, _toDisplayString(_ctx.commentError), 1))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_ctx.user.isAdmin && _ctx.approved !== undefined)
      ? (_openBlock(), _createBlock(_component_ion_item, { key: 2 }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_label, null, {
              default: _withCtx(() => [
                _createTextVNode("Status")
              ]),
              _: 1
            }),
            _createVNode(_component_ion_list, { slot: "end" }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_radio_group, {
                  slot: "end",
                  modelValue: _ctx.approved,
                  "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.approved) = $event)),
                  "allow-empty-selection": ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_item, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_label, null, {
                          default: _withCtx(() => [
                            _createTextVNode("Approved")
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_ion_radio, {
                          value: "approved",
                          slot: "end"
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_item, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_label, null, {
                          default: _withCtx(() => [
                            _createTextVNode("Rejected")
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_ion_radio, {
                          value: "rejected",
                          slot: "end"
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["modelValue"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_ion_button, {
      color: "success",
      class: "ion-float-right",
      style: {"margin":"12px"},
      type: "submit",
      onClick: _ctx.submit
    }, {
      default: _withCtx(() => [
        _createTextVNode("Submit")
      ]),
      _: 1
    }, 8, ["onClick"]),
    _createVNode(_component_ion_modal, {
      "is-open": _ctx.imageModal,
      onWillDismiss: _cache[13] || (_cache[13] = ($event: any) => (_ctx.imageModal = false))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ion_page, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_content, null, {
              default: _withCtx(() => [
                _createVNode(_component_ZoomableImg, {
                  onCloseModal: _cache[12] || (_cache[12] = ($event: any) => (_ctx.imageModal = false)),
                  sources: _ctx.thumbnail ? [_ctx.thumbnail.dataUrl] : _ctx.expense.imageUrls
                }, null, 8, ["sources"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["is-open"])
  ], 64))
}