
import {
  getFile,
  getThumbnail,
  clear,
  FileToUserFile,
  pickUserFile,
  pickUserImage,
  takePhoto,
  set,
} from "@/composables/getFile";
import { format } from "date-fns-tz";
import { computed, defineComponent, inject, ref } from "vue";

import { camera, close, folder, images } from "ionicons/icons";

import { useField, useForm } from "vee-validate";
import * as yup from "yup";

import {
  IonRow,
  IonCol,
  IonContent,
  IonDatetime,
  IonPage,
  IonModal,
  IonItem,
  IonLabel,
  IonSelect,
  IonSelectOption,
  IonInput,
  IonButton,
  IonIcon,
  IonImg,
  IonThumbnail,
  IonRadioGroup,
  IonRadio,
  IonList,
  IonPopover
} from "@ionic/vue";

import ZoomableImg from "@/components/ZoomableImg.vue";
import { useNetwork } from "@/composables/useNetwork";
import { Capacitor } from "@capacitor/core";

export default defineComponent({
  components: {
    IonRow,
    IonCol,
    IonContent,
    IonDatetime,
    IonModal,
    IonPage,
    IonItem,
    IonLabel,
    IonSelect,
    IonSelectOption,
    IonInput,
    IonButton,
    IonIcon,
    IonImg,
    IonThumbnail,
    IonRadioGroup,
    IonRadio,
    IonList,
    ZoomableImg,
    IonPopover
  },
  emits: ["submit"],
  props: {
    categories: Array,
    organizations: Array,
    edit: Boolean,
    expense: Object,
    user: { type: Object, required: true }
  },
  setup(props, { emit }) {
    const isMobile = inject("isMobile") as boolean;
    const isiOS = Capacitor.getPlatform() === "ios";
    const imageModal = ref(false);
    const popoverIsOpen = ref(false);
    const approved = ref();
    const popoverEvent = ref();
    const file = getFile();
    const thumbnail = getThumbnail();
    const { isInternet } = useNetwork();

    const categoryOrgMapReverse: { [key: string]: string } = {
      "civillo": "Civillo",
      "mondopin": "Mondo Pin",
      "precisionrentals": "Precision Rentals",
      "thinkspatial": "ThinkSpatial"
    };

    const organization = ref(props.expense?.organization);
    const filteredCategories = computed(() => {
      const org = props.user.isAdmin ? organization.value : categoryOrgMapReverse[props.user.email.match(/@([^.]+)\./)[1]];
      if(!org) {
        return [];
      }
      return props.categories?.filter((x: any) => x.organization === org);
    })

    // Validation
    const schema = yup.object({
      category: yup.string().required("*Required"),
      amount: yup
        .string()
        .test(
          "maxVal",
          "*Expenses must be less than $10,000",
          val => parseFloat(val as string) < 10000
        )
        .test(
          "isPositive",
          "Amount must be a positive number",
          val => parseFloat(val as string) > 0
        )
        .min(0, "Can't be a negative amount")
        .required("*Required"),
      description: yup
        .string()
        .nullable()
        .max(150, "*Max length 150 characters"),
      date: yup.string().required("*Required"),
      comment: yup.string().max(1000, "Max 1000 characters")
    });

    const theForm = useForm({ validationSchema: schema });

    const { value: amount, errorMessage: amountError } = useField("amount");
    const { value: category, errorMessage: categoryError } = useField(
      "category"
    );
    const { value: date, errorMessage: dateError } = useField("date");
    const { value: description, errorMessage: descriptionError } = useField(
      "description"
    );
    const { value: comment, errorMessage: commentError } = useField("comment");

    const fileError = ref<string>();

    const presetFields = async () => {
      if (props.expense) {
        const url = props.expense.imageUrls
          ? props.expense.imageUrls[0]
          : props.expense.imageUrl || "assets/placeholder.jpg";
        set({
          data: url
        }, true);
        await theForm.setFieldValue("date", props.expense.date);
        await theForm.setFieldValue("category", props.expense.category);
        await theForm.setFieldValue("amount", props.expense.amount);
        await theForm.setFieldValue("description", props.expense.description);
        if (props.expense.comment) {
          await theForm.setFieldValue(
            "comment",
            props.expense.comment
              .split(": ")
              .slice(1)
              .join(" ")
          );
        }
        approved.value = props.expense.approved
          ? "approved"
          : props.expense.approved === false
          ? "rejected"
          : null;
      }
    };
    presetFields();

    // Methods

    const setFile = async() => {
      clear();
      const userFile = await pickUserFile();
      set(userFile);
    }

    const setGalleryImage = async() => {
      clear();
      const userFile = await pickUserImage();
      set(userFile);
    }

    const pickFileClicked = async (event?: Event) => {
      if (isiOS) {
        popoverEvent.value = event;
        popoverIsOpen.value = true;
      } else {
        await setFile();
      }
    };

    const takePhotoClicked = async () => {
      const photo = await takePhoto();
      set(photo);
    };

    const pickIOSImage = async () => {
      clear();
      const userFile = await pickUserFile();
      set(userFile);
      popoverIsOpen.value = false;
    };

    const onAmountBlur = () => {
      const rounded = parseFloat(amount.value as string);
      amount.value = isNaN(rounded) ? "" : rounded.toFixed(2);
    };

    const handleBrowserImageUpload = async (event: any) => {
      // Handles an image uploaded from desktop browser
      if (!event.target.files.length) {
        return;
      }
      const file = event.target.files[0];
      const userFile = await FileToUserFile(file);
      set(userFile)
    };

    const resetForm = () => {
      theForm.resetForm();
      amount.value = 0;
      if (!isMobile) {
        (document.getElementById("fileInput") as HTMLInputElement).value = "";
      }
      clear();
    };

    const submit = async () => {
      const selectedCategory = filteredCategories?.value?.find((x: any) => x.name === category.value);

      if (!isInternet.value) {
        return;
      }

      if (
        !props.edit &&
        !file.value &&
        (!props.expense || !props.expense.imageUrls)
      ) {
        fileError.value = "*Required";
        return;
      }

      const isValid = (await theForm.validate()).valid;
      if (!isValid) {
        return;
      }

      // This simply checks if the expense file/image has been updated. Unupdated files will only have a
      // data property.
      const updatedFile =
        file.value && file.value.data && file.value.name && file.value.type;
      const expense = {
        user: props.user.firstName + " " + props.user.lastName,
        userId: props.user.publicId,
        category: selectedCategory,
        date: date.value,
        file: props.edit && !updatedFile ? null : file.value,
        amount: parseFloat(amount.value as string),
        description: description.value,
        comment: comment.value ? comment.value : null,
        approved:
          approved.value === "approved"
            ? true
            : approved.value === "rejected"
            ? false
            : null,
        publicId: props.expense ? props.expense.publicId : null
      };
      emit("submit", expense);
    };
    return {
      amount,
      amountError,
      approved,
      camera,
      close,
      category,
      categoryError,
      comment,
      commentError,
      date,
      dateError,
      description,
      descriptionError,
      popoverEvent,
      images,
      file,
      filteredCategories,
      folder,
      fileError,
      imageModal,
      isMobile,
      isiOS,
      organization,
      popoverIsOpen,
      thumbnail,
      handleBrowserImageUpload,
      format,
      onAmountBlur,
      pickFileClicked,
      pickIOSImage,
      resetForm,
      takePhotoClicked,
      setGalleryImage,
      setFile,
      submit
    };
  }
});
